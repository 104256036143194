import React, { Component } from 'react'


class NotFound extends Component {
    render() {
        return (
            <p>Busted</p>
        );
    }
}

export default NotFound;


