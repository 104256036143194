import React, { Component } from 'react'

class Menu extends Component {
    
      render() {
          return (
        <div className='menu'>
        <ul>
            <li><a href="#andrew">Andrew</a></li>
            <li><a href="#joe">Joe</a></li>
            <li><a href="#ian">Ian</a></li>
            
        </ul>
        </div>
          )
      }
    }

export default Menu